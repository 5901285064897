import React from 'react'
import Component from './index.js'

export default function editableTransformer({ data, ...props }) {
  if (
    !data?.layout?.layouts?.max?.items?.length ||
    data?.layout?.layouts?.max?.items?.length !== data?.tiles?.length
  ) {
    return <div>please save and reload</div>
  }
  return <Component data={data} {...props} />
}
